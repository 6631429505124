import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import { GetInTouchContent, GetInTouchContentVariables } from './__generated__/getInTouchContent.v2';
import * as query from './getInTouchContent.gql';

export const useGetInTouchContent = (
  options?: QueryHookOptions<GetInTouchContent, GetInTouchContentVariables>,
): QueryResult<GetInTouchContent, GetInTouchContentVariables> => {
  return useContentstackQuery(query.GetInTouchContent, options);
};
