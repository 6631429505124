import { isIE } from 'react-device-detect';

import { createDefaultTheme, createTheme, ThemeOptions } from '@sigfig/digital-wealth-core';

export { default as scotia } from './fonts/ttf/Scotia_Rg.ttf';
export { default as scotiaBold } from './fonts/ttf/Scotia_Bd.ttf';

const intermediateTheme = createTheme(createDefaultTheme());
const palette = {
  primary: {
    main: '#EC111A',
    dark: '#BE061B',
    light: '#FF575E',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#757575',
    dark: '#333333',
    light: '#757575',
    contrastText: '#FFFFFF',
    background: 'rgba(117, 117, 117, 0.08)',
  },
  text: {
    primary: '#333333',
    secondary: '#8C8C8C',
    disabled: '#BFBFBF',
  },
  action: {
    disabledBackground: '#F6F6F6',
    disabled: '#949494',
    disabledBorder: '#D6D6D6',
  },
  success: {
    main: '#138468',
    dark: '#3B873E',
    light: '#7BC67E',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#009DD6',
    dark: '#007EAB',
    light: '#64B6F7',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FB6330',
    dark: '#C77700',
    light: '#FFB547',
    lightBg: '#FFF3EF',
    contrastText: '#000000, 87%',
  },
  error: {
    main: '#BE061B',
    dark: '#E31B0C',
    light: '#F88078',
    contrastText: '#FFFFFF',
  },
  background: {
    default: '#FAFAFA',
    light: '#FEECED',
  },
  other: {
    backdrop: '#000000',
    divider: '#F6F6F6',
    stroke: '#D6D6D6',
  },
};

const components: ThemeOptions['components'] = {
  MuiAlert: {
    styleOverrides: {
      action: {
        [intermediateTheme.breakpoints.up('md')]: {
          alignItems: 'center',
        },
        '.MuiButton-text': { color: palette.text.primary },
      },
      standard: {
        border: '1px solid',
        lineHeight: '21px',
        // override Typography that may be rendered by RteContent but not a Link
        '.MuiTypography-root:not(.MuiLink-root)': { color: 'inherit', lineHeight: 'inherit' },
      },
      standardInfo: { borderColor: palette.info.main, '.MuiAlert-icon': { color: palette.info.main } },
      standardError: { borderColor: palette.error.main, '.MuiAlert-icon': { color: palette.error.main } },
      standardSuccess: { borderColor: palette.success.main, '.MuiAlert-icon': { color: palette.success.main } },
      standardWarning: {
        borderColor: palette.warning.main,
        backgroundColor: palette.warning.lightBg,
        '.MuiAlert-icon': { color: palette.warning.main },
      },
      icon: { marginRight: 10 },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: { fontSize: '16px', fontWeight: 700, lineHeight: '24px' },
    },
  },
  MuiButton: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: 8,
        textTransform: 'none',
        '&.MuiButton-sizeLarge': {
          fontFamily: 'Scotia-Bold',
          fontSize: 16,
          padding: '17px 36px',
          lineHeight: '20px',
        },
        '&.MuiButton-sizeMedium': {
          fontFamily: 'Scotia-Headline',
          fontSize: 14,
          padding: '15px 32px',
          lineHeight: '18px',
        },
        '&.MuiButton-sizeSmall': {
          fontSize: 14,
          padding: '13px 24px',
          lineHeight: '18px',
          '&.MuiButton-outlined': {
            padding: '12px 24px',
          },
        },
        '&.MuiButton-text': {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        [intermediateTheme.breakpoints.down('md')]: {
          padding: 0,
        },
        '&.Mui-checked': {
          color: palette.info.dark,
        },
        pointerEvents: 'auto',
        color: palette.secondary.main,
        '&.Mui-disabled': {
          '.MuiSvgIcon-root': {
            fill: palette.other.stroke,
            position: isIE ? 'relative' : undefined,
            left: isIE ? '-5px' : undefined,
          },
          '.MuiIconButton-label': {
            height: 14,
            width: 14,
            margin: '5px',
            backgroundColor: palette.action.disabledBackground,
          },
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '6px',
      },
      label: {
        fontFamily: 'Scotia-Bold',
        fontSize: 12,
        lineHeight: '13px',
      },
      outlined: {
        borderWidth: '1.5px',
        borderColor: palette.secondary.main,
      },
      filled: {
        backgroundColor: '#F6F6F6',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: () => ({}),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${palette.other.divider}`,
        padding: 24,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        '& .MuiTypography-root': {
          // override the MuiTypography override for error color
          color: 'inherit',
        },
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        '&.Mui-disabled .MuiTypography-root': {
          // override the MuiTypography override for disabled color
          color: palette.text.disabled,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: 16,
        fontWeight: 700,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        '&.Mui-focusVisible': { outline: isIE ? `${palette.primary.main} solid` : 'auto' },
      },
    },
    defaultProps: {
      underline: 'always',
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        '&, .MuiTypography-root': { color: 'inherit' },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: 'default',
        disableRipple: true,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          backgroundColor: palette.info.dark,
        },
      },
      switchBase: {
        '&.Mui-checked': {
          color: palette.info.dark,
        },
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: palette.info.dark,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        '&:first-of-type': {
          paddingLeft: 16,
        },
        '&': {
          borderColor: 'rgba(224, 224, 224, 1)',
        },
        '&.MuiTableCell-head': {
          padding: 16,
        },
        '&:last-of-type': {
          paddingRight: 16,
        },
        padding: '7px 16px',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        '.MuiTableCell-head': {
          textTransform: 'none',
          fontSize: 16,
          fontWeight: 700,
        },
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderColor: palette.other.stroke,
        '&.Mui-selected': {
          color: palette.text.primary,
          backgroundColor: palette.other.divider,
        },
      },
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: { color: 'secondary' },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: palette.text.primary,
        '&.MenuItem-label': {
          color: 'inherit',
        },
      },
    },
  },
};

/**
 * The fontWeight is specified in the font-face fontFamily definition.
 */

const typography: ThemeOptions['typography'] = {
  h1: {
    fontFamily: 'Scotia-Headline',
    fontSize: 48,
    lineHeight: '54px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 36,
      lineHeight: '41px',
    },
  },
  h2: {
    fontFamily: 'Scotia-Headline',
    fontSize: 32,
    lineHeight: '40px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 28,
      lineHeight: '35px',
    },
  },
  h3: {
    fontFamily: 'Scotia-Headline',
    fontSize: 24,
    lineHeight: '30px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 22,
      lineHeight: '30px',
    },
  },
  h4: {
    fontFamily: 'Scotia-Headline',
    fontSize: 21,
    lineHeight: '26px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 21,
      lineHeight: '26px',
    },
  },
  h5: {
    fontFamily: 'Scotia-Regular',
    fontSize: 20,
    lineHeight: '30px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 20,
      lineHeight: '30px',
    },
  },
  h6: {
    fontFamily: 'Scotia-Regular',
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: '400',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  subtitle1: {
    fontFamily: 'Scotia-Bold',
    fontSize: 18,
    lineHeight: '27px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  subtitle2: {
    fontFamily: 'Scotia-Bold',
    fontSize: 16,
    lineHeight: '20px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  subtitle3: {
    fontFamily: 'Scotia-Bold',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '28px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  body1: {
    fontFamily: 'Scotia-Regular',
    fontSize: 18,
    lineHeight: '27px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  body2: {
    fontFamily: 'Scotia-Regular',
    fontSize: 16,
    lineHeight: '24px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
  body3: {
    fontFamily: 'Scotia-Regular',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '22px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  button: {
    fontFamily: 'Scotia-Bold',
  },
  caption: {
    fontFamily: 'Scotia-Regular',
    fontSize: 14,
    lineHeight: '21px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  captionBold: {
    fontFamily: 'Scotia-Regular',
    fontSize: 14,
    fontWeight: 700,
    lineHeight: '21px',
    [intermediateTheme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  tooltip: {
    fontFamily: 'Scotia-Regular',
    fontSize: 12,
    letterSpacing: 'normal',
    lineHeight: '16px',
  },
  buttonLarge: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: '20px',
  },
  buttonMedium: {
    fontFamily: 'Scotia-Bold',
    fontSize: 16,
    lineHeight: '18px',
  },
};
const sfComponents: ThemeOptions = {
  sfAccount: {
    styles: {
      accountAlertMessage: {
        '.MuiTypography-root': { color: 'error.main' },
      },
    },
  },
  sfAccountDetails: {
    root: {},
    styles: {
      dropdownLabel: {
        fontWeight: 600,
      },
    },
  },
  sfAccountInfo: {
    styles: {
      infoChip: {
        textTransform: 'uppercase',
      },
    },
  },
  sfChecklist: {
    styles: {
      title: { color: 'text.primary' },
    },
    typographyVariants: {
      item: 'body2',
    },
  },
  sfDropdown: {
    styles: {
      drawerSx: {
        '.MuiMenuItem-root': {
          '&.Mui-selected': {
            '.MenuItem-icon': { mt: 0, pr: 0.25 },
            '.MuiTypography-root': { color: 'primary.main' },
          },
          '.MuiGrid-root.MuiGrid-container': {
            flexWrap: 'nowrap',
            whiteSpace: 'break-spaces',
          },
        },
      },
      madlib: {
        root: {
          '.MuiSelect-select .MuiTypography-root': { color: 'text.primary' },
        },
      },
    },
  },
  bnsDisclaimerGroup: {
    root: {
      '.MuiTypography-root': { color: 'secondary.main', textAlign: 'left' },
    },
    styles: {},
  },
  sfGoals: {
    styles: {
      savingsView: {
        projectionColor: '#009DD6',
        axisLineColor: '#E2E8EE',
        goalProjectionColor: '#949494',
        tooltipStyles: {
          border: '1px solid #F6F6F6',
          padding: '16px 20px',
          gap: 0,
          boxShadow: '0px 1px 15px 0px #607DF41F',
        },
      },
    },
  },
  bnsHeader: {
    styles: {
      subHeader: {
        backgroundColor: palette.background.default,
        width: '100%',
      },
    },
  },
  bnsRiskSpectrum: {
    styles: {
      colors: ['#0f877b', '#5b904d', '#b09232', '#ea6e30', '#dd3524'],
      indicatorColor: palette.secondary.dark,
    },
  },
  sfKebabMenu: {
    styles: {
      button: {
        // Match the padding of the outlined button
        '&.MuiButton-sizeSmall': {
          '&, &.MuiButton-outlined': {
            p: '9px',
          },
        },
      },
    },
  },
  sfMadLibs: {
    root: {
      '.MuiFormControl-root .MuiAlert-root': { border: 'none' },
    },
    styles: {
      headingColor: 'text.primary',
      secondaryInputColor: palette.text.secondary,
    },
  },
  sfModal: {
    root: {
      '.MuiIconButton-label': {
        flexDirection: 'column',
      },
    },
    styles: {
      dialogActions: {
        [intermediateTheme.breakpoints.down('md')]: {
          backgroundColor: palette.secondary.background,
        },
      },
      dialogTitle: {
        [intermediateTheme.breakpoints.down('md')]: {
          backgroundColor: palette.secondary.background,
        },
        fontFamily: 'Scotia-Headline',
      },
    },
  },
  bnsOnboardingContext: {
    root: {},
    styles: {
      stepLabel: {
        circle: {
          stroke: '#7849b8',
          cx: '13px',
          cy: '13px',
          transform: 'scale(0.9)',
          strokeWidth: 1.5,
        },
        '&.Mui-disabled': {
          '.MuiStepIcon-root': {
            opacity: '.5',
            circle: {
              stroke: '#7849b8',
            },
            '.MuiStepIcon-text': {
              fill: '#F2609E',
            },
          },
        },
      },
      stepper: {
        '.MuiStepConnector-line': {
          borderLeftStyle: 'dashed',
        },
        '.MuiStepIcon-root.Mui-active': {
          color: 'transparent',
        },
        '.MuiStepIcon-text': {
          stroke: '#F2609E',
          fill: '#F2609E',
        },
      },
    },
  },
  bnsMultipleSelectionPortfolios: {
    styles: {
      tabs: {
        borderBottom: `1px solid ${palette.other.divider}`,
      },
      portfolioDetails: {
        background: palette.background.default,
        p: 2,
        mt: 4,
      },
      table: {
        'div.MuiAlert-root': {
          border: `1px solid ${palette.primary.dark}`,
          mb: 2,
          mx: -2,
        },
      },
    },
  },
  sfPerformanceChart: {
    styles: {
      lineColor: palette.info.dark,
      displayDialog: {
        maxWidth: 300,
        p: 3,
        boxShadow: '0px 2px 10px rgba(0, 34, 91, 0.11);',
        boxSizing: { xs: 'border-box' },
      },
    },
  },
  bnsPlayback: {
    styles: {
      iconSize: {
        height: 80,
        width: 80,
      },
    },
  },
  bnsAppropriateness: {
    styles: {
      iframeStyle: {
        border: 'none',
        background: palette.background.default,
        margin: '12px 0',
      },
    },
  },
  bnsPortfolioDetails: {
    styles: {
      heading: {
        color: palette.secondary.main,
        fontFamily: 'Scotia-Bold',
      },
      label: {
        '.MuiTypography-caption': {
          color: palette.text.secondary,
        },
      },
      iconSize: {
        height: 108,
        width: 108,
        [intermediateTheme.breakpoints.down('md')]: {
          height: 80,
          width: 80,
        },
      },
      link: {
        alignSelf: 'center',
        color: 'info.dark',
        ml: 0.5,
        textDecorationColor: palette.info.dark,
      },
    },
  },
  bnsPortfolioCompositionDetails: {
    styles: {
      build: '#CC2B30',
      defend: '#1A1919',
      pay: '#8A8B8A',
      FIXED_INCOME: '#CC2B30',
      FOREIGN_EQUITY: '#1A1919',
      CANADIAN_EQUITY: '#8A8B8A',
      CASH_EQUIVALENT: '#B4B5B4',
    },
  },
  bnsPortfolioSelection: {
    styles: {
      iconSize: {
        height: 100,
        width: 100,
        [intermediateTheme.breakpoints.down('md')]: {
          height: 80,
          width: 80,
        },
      },
    },
  },
  sfQuestionnaire: {
    styles: {
      card: {
        background: 'linear-gradient(0deg, rgba(111, 119, 121, 0.1), rgba(111, 119, 121, 0.1)), #FFFFFF',
        borderColor: 'rgba(0, 0, 0, 0.12)',
        minHeight: 300,
      },
      heading: {
        color: 'text.primary',
      },
    },
  },
  sfRadioGroup: {
    styles: {
      radioIcons: {
        icon: {
          borderRadius: '50%',
          marginTop: 4,
          marginLeft: 4,
          width: 16,
          height: 16,
          backgroundColor: '#FFF',
          border: `1px solid ${palette.secondary.main}`,
          'input:disabled ~ &': {
            background: palette.action.disabledBackground,
            borderColor: palette.action.disabledBorder,
          },
        },
        checkedIcon: {
          borderColor: palette.info.dark,
          backgroundColor: palette.info.dark,
          '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#FFF,#FFF 28%,transparent 32%)',
            content: '""',
          },
          'input:disabled ~ &': {
            '&:before': {
              backgroundImage: `radial-gradient(${palette.secondary.main}, ${palette.secondary.main} 28%,transparent 32%)`,
            },
          },
        },
      },
      radioBorder: {
        base: {
          border: `1px solid ${palette.other.stroke}`,
          borderRadius: 2,
          mx: 0,
          p: 2,
        },
        selectedColor: 'info.dark',
      },
    },
  },
  sfRiskSpectrum: {
    root: {
      '.MuiSvgIcon-root': {
        fontSize: '1.7rem',
      },
    },
    styles: {
      background: 'linear-gradient(90deg, #007EAB 0%, #138468 23.29%, #FB6330 61.52%, #BE0618 100%)',
      indicatorSecondaryRoot: {
        color: 'text.primary',
      },
    },
  },
  sfTooltip: {
    styles: {
      content: {
        p: 1,
        a: {
          color: 'text.primary',
          fontWeight: 700,
        },
      },
    },
  },
};

export const defaultTheme = createDefaultTheme({
  components,
  palette,
  typography,
  ...sfComponents,
});
