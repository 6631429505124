import { QueryHookOptions, QueryResult, useSymphonyQuery } from '@sigfig/digital-wealth-core';

import {
  GetClientCurrentAccount,
  GetClientCurrentAccount_client_financialAccounts,
  GetClientCurrentAccountVariables,
} from './__generated__/getClientCurrentAccounts.v2';
import {
  GetClientDigitalWealthAccounts,
  GetClientDigitalWealthAccountsVariables,
} from './__generated__/getClientDigitalWealthAccounts.v2';
import { GetClientInfo, GetClientInfoVariables } from './__generated__/getClientInfo.v2';
import * as getClientInfo from './getClientInfo.gql';
import * as getClientDigitalWealthAccounts from './getClientDigitalWealthAccounts.gql';
import * as getClientCurrentAccount from './getClientCurrentAccounts.gql';

export type CurrentFinancialAccount = GetClientCurrentAccount_client_financialAccounts;

export const useGetClientInfo = (
  options?: QueryHookOptions<GetClientInfo, GetClientInfoVariables>,
): QueryResult<GetClientInfo, GetClientInfoVariables> => {
  return useSymphonyQuery(getClientInfo.GetClientInfo, options);
};

export const useGetClientDigitalWealthAccounts = (
  options?: QueryHookOptions<GetClientDigitalWealthAccounts, GetClientDigitalWealthAccountsVariables>,
): QueryResult<GetClientDigitalWealthAccounts, GetClientDigitalWealthAccountsVariables> => {
  return useSymphonyQuery(getClientDigitalWealthAccounts.GetClientDigitalWealthAccounts, options);
};

export const useGetClientCurrentAccount = (
  options?: QueryHookOptions<GetClientCurrentAccount, GetClientCurrentAccountVariables>,
): QueryResult<GetClientCurrentAccount, GetClientCurrentAccountVariables> =>
  useSymphonyQuery(getClientCurrentAccount.GetClientCurrentAccount, options);
