import './index.css';

import * as Sentry from '@sentry/react';
import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';

import {
  CoreConfigProvider,
  createTheme,
  EventBusProvider,
  initializeNewRelic,
  LiveAnnouncer,
  PostHogProvider,
  sfPosthog,
  ThemeProvider,
} from '@sigfig/digital-wealth-core';

import App from './App';
import { CookieManager } from './components/CookieManager';
import { PageLoading } from './components/Loading';
import config, { coreConfigs, isProdOrStg, isSqa9 } from './config';
import { AccountNumberProvider } from './contexts/AccountNumber';
import { AppProvider } from './contexts/App';
import { CertificateNumberProvider } from './contexts/CertificateNumber';
import { EntryContextProvider } from './contexts/Entry';
import * as serviceWorker from './serviceWorker';
import { defaultTheme } from './theme';

initializeNewRelic(config.newRelic.licenseKey, config.newRelic.applicationId);

type LogLevel = 'debug' | 'error' | 'info' | 'warn';
type PostHogSentryLogLevel = Exclude<LogLevel, 'warn'> | 'warning';

const getLogLevels = (): Array<LogLevel> => {
  if (isProdOrStg()) {
    return ['error'];
  }
  if (process.env.NODE_ENV === 'development' || isSqa9()) {
    // SQA9 is a load testing environment, so turn off logging.
    return [];
  }
  return ['warn', 'error'];
};

const getPosthogSentryIntegrationLogLevels = (): Array<PostHogSentryLogLevel> => {
  return getLogLevels().map(level => (level === 'warn' ? 'warning' : level));
};

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: getLogLevels(), // TODO: revisit this after warnings are cleaned up in https://sigfig.atlassian.net/browse/ANR-7195
    }),
    sfPosthog.sentryIntegration({
      organization: config.sentry.orgId,
      projectId: config.sentry.projectId,
      prefix: config.sentry.sentryInstance,
      severityAllowList: getPosthogSentryIntegrationLogLevels(),
    }),
  ],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary>
      <PostHogProvider
        apiKey={config.posthog.token}
        options={{
          ...sfPosthog.DEFAULT_CONFIG,
          opt_out_capturing_by_default: true,
        }}
      >
        <CookieManager>
          <ThemeProvider theme={createTheme(defaultTheme)}>
            <CoreConfigProvider value={coreConfigs}>
              <EntryContextProvider>
                <EventBusProvider>
                  <LiveAnnouncer>
                    <AppProvider>
                      <Suspense fallback={<PageLoading />}>
                        <AccountNumberProvider>
                          <CertificateNumberProvider>
                            <App />
                          </CertificateNumberProvider>
                        </AccountNumberProvider>
                      </Suspense>
                    </AppProvider>
                  </LiveAnnouncer>
                </EventBusProvider>
              </EntryContextProvider>
            </CoreConfigProvider>
          </ThemeProvider>
        </CookieManager>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
