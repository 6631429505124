import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import { GetFooterContent, GetFooterContentVariables } from './__generated__/getFooterContent.v2';
import * as query from './getFooterContent.gql';

export const useGetFooterContent = (
  options?: QueryHookOptions<GetFooterContent, GetFooterContentVariables>,
): QueryResult<GetFooterContent, GetFooterContentVariables> => {
  return useContentstackQuery(query.GetFooterContent, options);
};
