import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import {
  GetSomethingWentWrongContent,
  GetSomethingWentWrongContentVariables,
} from './__generated__/getSomethingWentWrongContent.v2';
import * as query from './getSomethingWentWrongContent.gql';

export const useSomethingWentWrongContent = (
  options?: QueryHookOptions<GetSomethingWentWrongContent, GetSomethingWentWrongContentVariables>,
): QueryResult<GetSomethingWentWrongContent, GetSomethingWentWrongContentVariables> => {
  return useContentstackQuery(query.GetSomethingWentWrongContent, options);
};
