import React, { lazy } from 'react';
import { createBrowserRouter, Outlet, RouteObject, RouterProvider, useLocation } from 'react-router-dom';

import { sfPosthog } from '@sigfig/digital-wealth-core';

import { Logout } from '../components/Logout';
import { Qa } from '../components/Qa';
import { ReturnToBns } from '../components/ReturnToBns';
import { isProdOrStg } from '../config';
import { FourOhFour } from '../containers/FourOhFour';
import { GetInTouch } from '../containers/GetInTouch';
import { SomethingWentWrong } from '../containers/SomethingWentWrong';

import { AppLayout } from './AppLayout';
import { AuthedApp } from './AuthedApp';
import { AuthWrapper } from './AuthWrapper';
import { Entry } from './Entry';
import { PageRoute, routes } from './routes';

const AccountAlreadyInProgress = lazy(() => import('../containers/AccountAlreadyInProgress'));
const AccountDetails = lazy(() => import('../containers/AccountDetails'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const EligibilityCheck = lazy(() => import('../containers/EligibilityCheck'));
const EntryRedirect = lazy(() => import('../components/EntryRedirect'));
const GoalsCreate = lazy(() => import('../containers/Goals/Create'));
const GoalsEdit = lazy(() => import('../containers/Goals/Edit'));
const Onboarding = lazy(() => import('../containers/Onboarding'));
const RetakeWrapper = lazy(() => import('./RetakeWrapper'));

const PosthogSpaWrapper = () => {
  sfPosthog.useCaptureSpaPageViews(useLocation());
  return <Outlet />;
};

const appRoutes: RouteObject[] = [
  { element: <Logout />, path: PageRoute.Logout },
  { element: <ReturnToBns />, path: PageRoute.ReturnToBns },
  { element: <FourOhFour />, path: '*' },
  { element: <Entry />, path: routes.redirect() },
  {
    element: <AuthedApp />,
    children: [
      {
        element: <EntryRedirect />,
        path: PageRoute.AuthedRedirect,
      },
      {
        element: <AppLayout />,
        children: [
          { element: <AccountAlreadyInProgress />, path: PageRoute.AccountAlreadyInProgress },
          {
            element: <AccountDetails />,
            path: PageRoute.AccountDetails,
          },
          {
            element: <Dashboard />,
            path: PageRoute.Dashboard,
          },
          {
            element: <EligibilityCheck />,
            path: PageRoute.EligibilityCheck,
          },
          {
            element: <EligibilityCheck />,
            path: PageRoute.EligibilityCheckForEntry,
          },
          {
            element: <Onboarding />,
            path: PageRoute.Onboarding,
          },
          {
            element: <Onboarding />,
            path: PageRoute.OnboardingStep,
          },
          {
            element: <GetInTouch />,
            path: PageRoute.GetInTouch,
          },
          { element: <GetInTouch isOffRamp />, path: PageRoute.OffRamp },
          {
            element: <Dashboard />,
            path: PageRoute.Goals,
          },
          { element: <GoalsCreate />, path: PageRoute.GoalsCreate },
          { element: <GoalsEdit />, path: PageRoute.GoalsEdit },
          { element: <RetakeWrapper />, path: PageRoute.Retake },
          { element: <SomethingWentWrong />, path: PageRoute.SomethingWentWrong },
        ],
      },
    ],
  },
];

if (!isProdOrStg()) {
  appRoutes.push({ element: <Qa />, path: '/qa' });
}

const router = createBrowserRouter([
  {
    element: <PosthogSpaWrapper />,
    children: [
      {
        element: (
          <AuthWrapper>
            <Outlet />
          </AuthWrapper>
        ),
        children: appRoutes,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
