import React, { createContext, useContext, useState } from 'react';
import { SetStateAction } from 'react';
import { Dispatch } from 'react';

const certificateNumberContext = createContext<
  | { certificateNumber: string | undefined; setCertificateNumber: Dispatch<SetStateAction<string | undefined>> }
  | undefined
>(undefined);

export const useCertificateNumberContext = () => {
  const context = useContext(certificateNumberContext);
  if (context === undefined) {
    throw new Error('useCertificateNumberContext must be used within a CertificateNumberProvider');
  }
  return context;
};

export const CertificateNumberProvider: React.FC<{ value?: string }> = ({ children, value }) => {
  const [certificateNumber, setCertificateNumber] = useState(value);
  return (
    <certificateNumberContext.Provider value={{ certificateNumber, setCertificateNumber }}>
      {children}
    </certificateNumberContext.Provider>
  );
};
