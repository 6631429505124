import { PageViewConfigs } from '../types';

export const pageViewConfigs: PageViewConfigs = {
  accountAlreadyInProgress: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:account already in progress',
    pageType: 'account already in progress',
  },
  accountDetails: {
    channel: 'sigfig-authenticated',
    pageName: 'sigfig:account details',
    pageType: 'details',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  accountSelection: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:account selection',
    pageType: 'account selection',
  },
  additionalProducts: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:additional products',
    pageType: 'additional products',
  },
  contextPage: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:triage',
    pageType: 'triage',
  },
  dashboard: {
    channel: 'sigfig-authenticated',
    pageName: 'sigfig:dashboard',
    pageType: 'dashboard',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  errorSubmitApplicationModal: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:error page',
    pageType: 'error page',
  },
  getInTouchGeneric: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:get in touch generic',
    pageType: 'get in touch generic',
  },
  getInTouchAdvisorOfframp: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:get in touch advisor offramp',
    pageType: 'get in touch advisor offramp',
  },
  goalsAssociateExistingAccountComplete: {
    applicationName: 'sigfig',
    channel: 'sigfig-authenticated',
    country: 'CA',
    pageName: 'sigfig:dashboard:goal setup:associate account:existing:complete',
    pageType: 'dashboard',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  goalsAssociateExistingAccountStart: {
    applicationName: 'sigfig',
    channel: 'sigfig-authenticated',
    country: 'CA',
    pageName: 'sigfig:dashboard:goal setup:associate account:existing:start',
    pageType: 'dashboard',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  goalsAssociateOtherAccountComplete: {
    applicationName: 'sigfig',
    channel: 'sigfig-authenticated',
    country: 'CA',
    pageName: 'sigfig:dashboard:goal setup:associate account:ofi:complete',
    pageType: 'dashboard',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  goalsAssociateOtherAccountStart: {
    applicationName: 'sigfig',
    channel: 'sigfig-authenticated',
    country: 'CA',
    pageName: 'sigfig:dashboard:goal setup:associate account:ofi:start',
    pageType: 'dashboard',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  goalsSetup: {
    applicationName: 'sigfig',
    channel: 'sigfig-authenticated',
    pageName: 'sigfig:dashboard:goal setup',
    pageType: 'dashboard',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  goalsSetupDetails: {
    applicationName: 'sigfig',
    channel: 'sigfig-authenticated',
    pageName: 'sigfig:dashboard:goal setup details',
    pageType: 'dashboard',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  investmentApplicationReview: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:review application',
    pageType: 'review application',
  },
  investmentProfile: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:strategy summary',
    pageType: 'strategy summary',
  },
  onboardingGoalSelection: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:goal selection',
    pageType: 'goal selection',
  },
  onboardingGoalDetails: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:goal details',
    pageType: 'goal details',
  },
  planRecommendation: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:select plan type',
    pageType: 'select plan type',
    platformType: 'sigfig',
    subSection: 'sigfig-authenticated',
  },
  productSelection: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:recommendations',
    pageType: 'recommendations',
  },
  productAppropriateness: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:product appropriateness',
    pageType: 'product appropriateness',
  },
  profileChange: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:financial situation change triage',
    pageType: 'financial situation triage',
  },
  madlibs: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:financial situation change input',
    pageType: 'financial situation input',
  },
  retakeRtqMadlibsAge: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Age',
    pageType: 'retake RTQ Age',
  },
  retakeRtqMadlibsTimeHorizon: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Time Horizon',
    pageType: 'retake RTQ Time Horizon',
  },
  retakeRtqMadlibsAnnualHouseHoldIncome: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Annual household income',
    pageType: 'retake RTQ Annual household income',
  },
  retakeRtqMadlibsNetWorth: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ net worth',
    pageType: 'retake RTQ net worth',
  },
  retakeRtqMadlibsInvestmentGoal: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Investment goal',
    pageType: 'retake RTQ Investment goal',
  },
  retakeRtqMadlibsInvestmentGoalInvestingForRetirementAge: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Investment goal Age',
    pageType: 'retake RTQ Investment goal Age',
  },
  retakeRtqMadlibsInvestmentGoalRetiredAndStillInvestingAge: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Investment goal retired and still investing Age',
    pageType: 'retake RTQ Investment goal retired and still investing Age',
  },
  retakeRtqMadlibsInvestmentGoalRetiredAndHaveBeenTakingIncomeFromMySavingsAge: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Investment goal retired and have been taking income from savings Age',
    pageType: 'retake RTQ Investment goal retired and have been taking income from savings Age',
  },
  retakeRtqInvestmentProfileNoChange: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Results No change',
    pageType: 'retake RTQ Results No change',
  },
  retakeRtqInvestmentProfileChange: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:retake RTQ Results change',
    pageType: 'retake RTQ Results change',
  },
  rtq1Objective: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:objective',
    pageType: 'objective',
  },
  rtq2Experience: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:experience',
    pageType: 'experience',
  },
  rtq3PercentageOfNetWorth: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:percentage',
    pageType: 'percentage',
  },
  rtq4LikelihoodOfLiquidity: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:liquidation',
    pageType: 'liquidation',
  },
  rtq5InvestmentApproach: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:approach',
    pageType: 'approach',
  },
  rtq6VolatilityComfort: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:investment profile:volatility',
    pageType: 'volatility',
  },
  somethingWentWrong: {
    channel: 'sigfig-Discovery & Onboarding',
    pageName: 'const:ipob:error page',
    pageType: 'error page',
  },
};
